@import "src/scss/module";

.MenuFooter {
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;

  @include media-breakpoint-up(sm) {
    flex-direction: row;
  }
}
