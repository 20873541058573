@import "src/scss/module";

.MainMenu {
  overflow: auto;

  :global {
    .nav-item:not(.dropdown-item) {
      border-bottom: 1px solid rgba($white, 0.1);
      font-size: #{$h3-font-size};
      font-weight: #{$font-weight-bold};
      padding-bottom: 0.75rem;

      & > .nav-link {
        padding: 0.25rem 0;
        display: flex;
        align-items: center;
        gap: 1rem;

        &:before {
          content: "";
          display: block;
          border-radius: 50%;
          width: 0;
          height: 0;
          background-color: #{$white};
          transition: 0.3s;
        }

        &:after {
          margin-left: auto;
        }

        span {
          position: relative;

          &:before {
            position: absolute;
            bottom: 0;
            left: 0;
            content: "";
            display: block;
            height: 0.5px;
            width: 0%;
            background-color: #{$green};
            transition: 0.3s;
            flex-grow: 0;
            flex-shrink: 0;
            opacity: 0;
          }
        }

        &:hover {
          color: #{$green} !important;

          &:before {
            width: 1rem;
            height: 1rem;
            background-color: #{$green};
          }

          span {
            &:before {
              width: 100%;
              opacity: 0.5;
            }
          }
        }
      }
    }

    .dropdown {
      & > .nav-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .dropdown-toggle {
      border: none;

      &::after {
        display: inline-flex;
        flex-shrink: 0;
        flex-grow: 0;
        align-items: center;
        justify-content: center;
        width: 18px;
        height: 18px;
        border: 0;
        background-size: contain;
        background-image: #{escape-svg(
            url('data:image/svg+xml,<svg viewBox="0 0 18 19" fill="white" xmlns="http://www.w3.org/2000/svg"><path d="M3.2654 5.92761C3.58153 5.65997 4.05477 5.69927 4.32242 6.0154L9.00002 11.5403L13.6776 6.0154C13.9453 5.69927 14.4185 5.65997 14.7346 5.92761C15.0508 6.19526 15.0901 6.66851 14.8224 6.98464L9.85463 12.8523C9.40565 13.3826 8.59438 13.3826 8.1454 12.8523L3.17761 6.98463C2.90997 6.66851 2.94927 6.19526 3.2654 5.92761Z"/></svg>')
          )};
        transition: 0.3s;
      }

      &[aria-expanded="true"] {
        &::after {
          transform: rotate(180deg);
        }
      }
    }

    .dropdown-menu {
      background-color: transparent;
      box-shadow: none !important;
      border: none;

      .dropdown-item {
        font-size: #{$font-size-base};
        font-weight: #{$font-weight-bold};
        background-color: transparent;
        border: none;
        padding-top: 0;
        padding-bottom: 0;
        white-space: wrap;

        .nav-link {
          padding-top: 0.25rem;
          padding-bottom: 0.25rem;
        }

        span {
          position: relative;

          &:before {
            position: absolute;
            bottom: 0;
            left: 0;
            content: "";
            display: block;
            height: 0.5px;
            width: 0%;
            background-color: #{$white};
            transition: 0.3s;
            flex-grow: 0;
            flex-shrink: 0;
            opacity: 0;
          }
        }

        &:hover {
          span {
            &:before {
              width: 100%;
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
}
