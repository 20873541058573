@import "src/scss/module";

.Header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1rem 0;
  z-index: $zindex-fixed;

  .toggle {
    position: fixed;
    z-index: $zindex-fixed;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    color: #141821;
    background-color: #{$white};
  }
}
